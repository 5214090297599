@import './../../../../styles/config.scss';

.card {
    position: relative;
    box-shadow: $box-shadow;
    border-radius: 5px;
    padding: 10px;
}

.table {
    .title {
        padding: 5px 0px;
        width: 150px;
        vertical-align: top;
    }

    .separator {
        padding: 5px 0px;
        width: 10px;
        vertical-align: top;
    }

    .content {
        padding: 5px 0px;
        vertical-align: top;
    }
}

.timeline {
    position: relative;

    .indicator {
        position: relative;
    }

    .dot {
        margin-top: 18px;
        z-index: 1;
        width: 11px;
        height: 11px;
        background-color: $gray-600;
        border-radius: 5px;

        &.active {
            border: 2px solid $green-600;
            background-color: transparent;
        }
    }

    .line {
        top: 29px;
        left: 5px;
        bottom: -38px;
        width: 1px;
        position: absolute;
        background-color: $gray-600;
    }
}