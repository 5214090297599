@import './../../../../../styles/config.scss';


    
@media (max-width: $screenBreakPoint1) {
    .cards {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;

        & > * {
            margin: 0px !important;
            width: calc(50% - 10px) !important;
        }
    }
}