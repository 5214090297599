@import './../../../styles/config.scss';

.container {
    background-color: $white;
    padding: 10px;
    border-radius: 10px;
    box-shadow: $box-shadow;

    .calendar {
        margin-top: 20px;
        border: 1px solid $gray-200;
        border-radius: 5px;

        .header {
            background-color: $gray-200;
            padding: 10px;
            border-right: 1px solid $gray-300;

            .secondary {
                font-weight: normal;
                color: $gray-800;
            }

            &:last-child {
                border-right: 0px;
            }
        }

        .column {
            padding: 10px;
            border-right: 1px solid $gray-300;

            &:last-child {
                border-right: 0px;
            }
        }
    }

    .mobile {
        display: none;
    }
}


@media screen and (max-width: $screen-tab-1-breakpoint) {
    .container {
        .desktop {
            display: none;
        }

        .mobile {
            display: block;
        }
    }
}


@media screen and (max-width: $screen-phone-1-breakpoint) {
    .container {
        .desktop {
            display: none;
        }

        .mobile {
            display: block;
        }
    }
}