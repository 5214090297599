@import './../../../styles/config.scss';

.buttonCard {
    border-radius: $base-border-radius;
    -webkit-transition: background-color 0.3s; /* For Safari 3.0 to 6.0 */
    transition: background-color 0.3s; /* For modern browsers */

    &.medium {
        padding: 20px;
    }

    &.small {
        padding: 10px;
    }

    &.primary {
        background-color: $primary-600;

        * {
            color: $white;
        }

        &:hover {
            background-color: $primary-700;
        }
    }

    &.default {
        background-color: $gray-300;

        * {
            color: $dark;
        }

        &:hover {
            background-color: $gray-400;
        }
    }

    &.light {
        background-color: $white;

        * {
            color: $dark;
        }

        &:hover {
            background-color: $gray-100;
        }
    }

    &:hover,
    &:hover label {
        cursor: pointer;
    }

    &.shadow {
        box-shadow: $box-shadow;
    }
}