@import './config.scss';


@font-face {
    font-family: "dmsans";
    src: url('./../assets/fonts/dmsans/DMSans-Regular.ttf');
    src: url('./../assets/fonts/dmsans/DMSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "dmsans";
    src: url('./../assets/fonts/dmsans/DMSans-Italic.ttf');
    src: url('./../assets/fonts/dmsans/DMSans-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "dmsans";
    src: url('./../assets/fonts/dmsans/DMSans-Bold.ttf');
    src: url('./../assets/fonts/dmsans/DMSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "dmsans";
    src: url('./../assets/fonts/dmsans/DMSans-BoldItalic.ttf');
    src: url('./../assets/fonts/dmsans/DMSans-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "dmsans";
    src: url('./../assets/fonts/dmsans/DMSans-Medium.ttf');
    src: url('./../assets/fonts/dmsans/DMSans-Medium.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "dmsans";
    src: url('./../assets/fonts/dmsans/DMSans-MediumItalic.ttf');
    src: url('./../assets/fonts/dmsans/DMSans-MediumItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

$font-primary: 'dmsans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';


img {
    max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0px;
}

a,
button.ms-Link {
    color: $themePrimary;
    text-decoration: none;

    &:hover,
    &:focus {
        color: $themeDarker;
        text-decoration: none;
    }
}

a label {
    cursor: pointer;
}

.card {
    background-color: $white;
}

.separator {
    margin: 20px 0px !important;
    border-bottom: 1px solid $neutralLight;
}

.jl-form--grouping:not(:last-child) {
    padding-bottom: 20px;
    border-bottom: 1px solid $neutralLight;
}

.jl-persona {
    padding: 6px;
    background-color: $neutralLighter;
    border-radius: $base-border-radius;

    &.clickable:hover {
        background-color: $neutralLight;
    }
}

.detailsListActionRow {
    padding: 0px auto;

    .detailsListActionButton {
        min-height: unset !important;
        height: unset !important;
        min-width: auto !important;

        &.primary {
            font-size: 12px;
            font-weight: normal;
        }
    }
}

.contentContainer {
    overflow: auto;
    margin-top: 20px !important;
    padding: 0px;


    &>.content {
        height: auto;
        background-color: $white;
        border-radius: $base-border-radius;
        box-shadow: $box-shadow;

        .pivotContent {
            padding: 20px;
        }
    }
}

.tag {
    padding: 2px 8px;
    border-radius: 5px;
    white-space: nowrap;
    background: $neutralLight;
    line-height: 2;

    &.cancel,
    &.error {
        background: $tag-cancel;
        color: $white;
    }

    &.active {
        background: $tag-active;
        color: $white;
    }

    &.warning {
        background: $tag-warning;
        color: $white;
    }

    &.success {
        background: $tag-success;
        color: $white;
    }

    &>i {
        margin-right: 5px;
        top: 2px;
        position: relative;
    }
}

.list-table {
    width: 100%;
    border-collapse: collapse;

    &>thead th {
        text-align: left;
        border-bottom: 1px solid $neutralLighter;

        &:hover {
            background-color: $neutralLight;
            ;
        }

        span {
            font-weight: 600;
        }
    }

    td,
    th {
        padding: 12px;
    }

    &>tbody>tr:hover {
        background-color: $neutralLighterAlt;
    }
}

.trackingStatus-container {
    overflow-x: auto;

    .trackingStatus-list {
        display: flex;
        background: $neutralDark;
        color: $white;
        min-width: calc(100% - 40px);
        width: fit-content;
        padding: 20px;
        margin: 0px;

        .trackingStatus-listItem {
            flex-grow: 1;
            list-style-type: none;

            &:not(:last-child) {
                margin-right: 15px;
            }

            .trackingStatus-image {
                max-width: 100px;
            }

            .trackingStatus-text {
                max-width: 120px;
                text-align: center;
            }

            &.trackingStatus-active {
                color: $themePrimary;
            }
        }
    }
}

@media screen and (max-width: $screenBreakPoint1) {
    .contentContainer {
        height: calc(100vh - 180px);
    }
}

.ms-MessageBar {
    border-radius: 4px;
}

.ms-Label {
    font-family: $font-family-dmsans;
    font-size: $label-xsmall-font-size;
    padding: 3px 0px;
}

.ms-Button.ms-Dropdown-item {
    border-radius: 0px;
}

.ms-Button {
    border-radius: $base-border-radius;
    min-height: 40px;

    .ms-Button-label {
        font-family: $font-primary;
        font-size: 14px;
    }

    .ms-Icon {
        font-size: $text-small-font-size;
        height: unset;
        min-height: unset;
        line-height: unset;
    }

    &.rounded {
        background: $neutralLighter;
        border-radius: 50%;
        width: 44px;

        &>span {
            justify-content: center;
            font-weight: 600;
        }

        &:hover {
            background-color: $neutralLight;
        }
    }
}

/*.ms-DatePicker {
    .ms-TextField {
        .ms-TextField-fieldGroup {
            min-height: unset;
            height: unset;
        }

        .ms-TextField-field {
            align-items: center;
            padding: 8px 10px;
            font-family: $font-family-dmsans;
            font-size: $text-small-font-size;
            line-height: unset;
        }
    }
}*/

.ms-TextField {
    .ms-TextField-fieldGroup {
        .ms-TextField-field {
            align-items: center;
            font-family: $font-family-dmsans;
            font-size: $text-small-font-size;
            height: 32px;
            min-height: 32px;

            &:not(textarea) {
                line-height: 32px;
            }
        }
    }

    .ms-TextField-suffix,
    .ms-TextField-prefix {
        background-color: transparent;
        font-family: $font-family-dmsans;
        font-size: $text-small-font-size;
        padding-top: 4px;
    }

    &.ms-TextField--multiline {
        .ms-TextField-fieldGroup {
            .ms-TextField-field {
                height: auto;
            }
        }
    }

    &.is-disabled {
        .ms-Label {
            color: $black;
        }

        .ms-TextField-field {
            color: $gray-900;
            border: 1px solid $gray-600;
            border-radius: 4px;
        }
    }
}

.ms-SearchBox {
    height: 32px;
    min-height: 32px;
    
    border-radius: $base-border-radius;

    &.is-active::after {
        border-radius: $base-border-radius;
    }

    .ms-SearchBox-field {
        padding-top: 1px;
        min-width: 250px;
        font-family: $font-family-dmsans;
        font-size: $text-small-font-size;
    }

    .ms-SearchBox-icon {
        font-size: $text-small-font-size;
    }
}

.ms-TextField-fieldGroup {
    border-radius: 4px;

    &::after {
        border-radius: 4px;
    }
}

.ms-Dialog {
    .ms-Dialog-main {
        width: 100%;
        max-width: 470px;
    }
}

.ms-Dropdown-container {
    &.borderless .ms-Dropdown-title {
        border-color: transparent;
    }

    .ms-Dropdown-title {
        font-family: $font-family-dmsans;
        font-size: $text-small-font-size;
    }

    .ms-Dropdown-caretDownWrapper {
        padding: 0px 8px;
        right: 0px;
    }

    .ms-Dropdown-title,
    .ms-Dropdown::after {
        border-radius: 4px;
    }
}

.ms-Dropdown-callout {
    .ms-Dropdown-header {
        padding-left: 10px;
        height: 32px;
    }
}

.ms-DetailsList {
    .ms-DetailsHeader {
        padding-top: 0px;

        .ms-DetailsHeader-cellName {
            font-family: $font-family-dmsans;
            font-size: 12px;
        }
    }

    .ms-DetailsRow:hover {
        background-color: $neutralLighterAlt;
    }

    .columnStack {
        height: 100%;

        &.textOnly {
            padding: 12px 0px;
        }
    }
}

.ms-Persona {
    width: 100%;
    
    .ms-Persona-primaryText {
        font-family: $font-family-dmsans;
        font-size: $label-small-font-size;
        font-weight: 600;
    }

    .ms-Persona-secondaryText {
        font-family: $font-family-dmsans;
        font-size: $text-small-font-size;
    }

    .ms-Persona-initials>span {
        color: $white !important;
    }
}

.ms-Panel {
    .ms-Panel-main {
        .ms-Panel-contentInner {
            padding-top: 20px;

            .ms-Panel-footer {
                border-top: 1px solid $neutralLighter;
            }
        }
    }
}

.ms-BasePicker {
    background-color: $white;

    input {
        font-family: $font-family-dmsans;
        font-size: $text-small-font-size;
        padding: 8px 10px;
    }

    .ms-BasePicker-text {
        min-height: unset;
        height: unset;
        min-width: unset;
    }

    .ms-TagItem {
        display: flex;
        align-items: center;

        .ms-TagItem-text {
            line-height: unset;
            font-family: $font-family-dmsans;
            font-size: $text-small-font-size;
        }
    }

    .ms-BasePicker-text,
    .ms-BasePicker-text::after {
        border-radius: 4px;
    }

    .ms-PickerPersona-container {
        height: 32px;
        padding: 0px 5px;
    }

    .ms-PickerItem-removeButton {
        height: 24px;
        min-height: unset;
    }
}

.ms-Suggestions-item {
    .ms-Button {
        padding: 6px 10px;
        height: auto;
        min-height: auto;

        .ms-PeoplePicker-personaContent {
            padding: 0px;
        }
    }
}

.ms-MessageBar {
    .ms-MessageBar-icon {
        margin: 14px 0px 14px 12px;
    }

    .ms-MessageBar-text {
        margin: 14px;
    }
}

.ms-Breadcrumb {
    margin-top: 0px;
}

.ms-Suggestions-item {
    border-bottom: 1px solid $gray-400;
}

.linePivot {
    .ms-Pivot {
        line-height: 0.7;
        overflow-y: hidden;
        overflow-x: auto;
        border-bottom: 1px solid $neutralQuaternaryAlt;

        .ms-Pivot-link {
            border-top-left-radius: $base-border-radius;
            border-top-right-radius: $base-border-radius;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;

            &.is-selected .ms-Pivot-text {
                font-weight: 600;
            }
        }

        .ms-Pivot-text {
            font-family: $font-family-dmsans;
            font-size: $text-small-font-size;
        }
    }

    .ms-Button {
        margin-bottom: -1px;
    }
}

.ms-OverflowSet-item {
    height: unset;
    min-height: unset;
}

.ms-Button {
    height: 32px;
    min-height: 32px;
    padding: 0px 10px;

    .ms-Button-label {
        font-family: $font-family-dmsans;
        font-size: $label-xsmall-font-size;
    }

    &.ms-Button.ms-Button--action.circle {
        background: $neutralLighterAlt;
        border-radius: 50%;
        padding: 0px 10px;
    }

    &.ms-Button--action.circle:hover {
        background: $themePrimary !important;

        * {
            color: $white;
        }
    }
    
    &.ms-Button--icon {
        padding: 10px;
        min-height: unset;
        min-width: unset;
        border-radius: 50%;
        background: #eee;
        padding: 0px;

        .ms-Button-flexContainer {
            height: unset;
            min-height: unset;
            line-height: unset;
        }
    }
}

.ms-TextField-errorMessage>span {
    white-space: break-spaces;
}

// background
.bg-white {
    background-color: $white;
}

.bg-error {
    background-color: $background-error;
}

.bg-success {
    background-color: $background-success;
}

.bg-warning {
    background-color: $background-warning;
}

.bg-default {
    background-color: $neutralLight;
}

// commandbar
.details-commandbar {
    border-radius: $base-border-radius;
    background-color: $white;
    box-shadow: $box-shadow;

    .ms-CommandBar {
        padding: 0px;
        background-color: transparent;
        border-radius: $base-border-radius;

        .ms-Button--commandBar {
            padding: 0px 10px;
            margin: 0px 5px;
        }
    }
}

// text color
.text-error {
    font-size: 12px;
    font-weight: 400;
    color: rgb(164, 38, 44);
    padding-top: 5px;
}













// v2 stylings
body {
    background-color: $gray-200;
    font-family: $font-primary;
}

.divider {
    height: 1px;
    width: 100%;
    background-color: $gray-300;
}

.dark-divider {
    height: 1px;
    width: 100%;
    background-color: $gray-600;
}

.card {
    border-radius: 4px;
    background-color: $white;
    box-shadow: $box-shadow;
}

.color-primary {
    color: $primary-600;
}

.color-darkgray {
    color: $gray-800;
}

.color-gray {
    color: $gray-600;
}

.color-green {
    color: $green-600;
}

.color-red {
    color: $red-600;
}

.color-yellow {
    color: $yellow-600;
}

.color-blue {
    color: $blue-600;
}

.color-white {
    color: $white;
}