@import './../../../../../styles/config.scss';

.documents {
    .uploadButton {
        padding: 10px 15px;
        border-radius: 4px;
        background-color: $white;
        border: 1px solid $gray-800;
        width: 33.3%;

        &:hover {
            background-color: $gray-200;
            cursor: pointer;
        }
    }

    .preview {
        justify-content: center;
        align-items: center;
        background-color: $gray-800;
        border-radius: 4px;
        padding: 20px;
    
        .image {
            object-fit: contain;
        }
    }

    .fileName {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}