$themePrimary: #e48825;
$themeLighterAlt: #fefaf5;
$themeLighter: #fbead9;
$themeLight: #f7d9b8;
$themeTertiary: #eeb476;
$themeSecondary: #e6943c;
$themeDarkAlt: #cc7921;
$themeDark: #ac661c;
$themeDarker: #7f4b14;
$neutralLighterAlt: #faf9f8;
$neutralLighter: #f3f2f1;
$neutralLight: #edebe9;
$neutralQuaternaryAlt: #e1dfdd;
$neutralQuaternary: #d0d0d0;
$neutralTertiaryAlt: #c8c6c4;
$neutralTertiary: #a19f9d;
$neutralSecondary: #605e5c;
$neutralPrimaryAlt: #3b3a39;
$neutralPrimary: #323130;
$neutralDark: #201f1e;
$black: #000000;
$white: #fff;

$tertiaryColor: #3d3e3e;

// order status color
$requested: #6c757d;
$quotecreated: #ffc107;
$quotesent: #007bff;
$cancelled: #dc3545;
$completed: #28a745;
$inprogress: $themePrimary;


$color-redDark: #a4262c;

$base-border-radius: 6px;

$screenBreakPoint1: 980px;
$screenBreakPoint2: 720px;
$screenBreakPoint3: 600px;

// tag color
$tag-default: #6c757d;
$tag-cancel: #dc3545;
$tag-warning: #e2aa00;
$tag-active: #007bff;
$tag-success: #28a745;


$primary-color: #e48826;
$secondary-color: #005247;
$white-color: #FFF7ED;
$black-color: #33221D;

$background-error: #fde7e9;
$background-success: #dff6dd;
$background-warning: #fff4ce;

@media (min-width: $screenBreakPoint1) {
    .hideOnDesktop {
        display: none;
    }
}

@media (max-width: $screenBreakPoint1) and (min-width: $screenBreakPoint2) {
    .hideOnTablet {
        display: none;
    }
}

@media (max-width: $screenBreakPoint2) {
    .hideOnMobile {
        display: none;
    }
}




// v2 stylings
$white: #fff;
$dark: #000;

$primary-100: #fefaf5;
$primary-200: #fbead9;
$primary-300: #f7d9b9;
$primary-400: #eeb578;
$primary-500: #e6953e;
$primary-600: #E48826;
$primary-700: #CC7A23;
$primary-800: #AC671D;
$primary-900: #7F4C16;

$gray-100: #FAF9F8;
$gray-200: #F3F2F1;
$gray-300: #EDEBE9;
$gray-400: #E1DFDD;
$gray-500: #D2D0CE;
$gray-600: #C8C6C4;
$gray-700: #A19F9D;
$gray-800: #8A8886;
$gray-900: #605E5C;

$blue-900: #004377;
$blue-800: #005ba1;
$blue-700: #006cbe;
$blue-600: #0078d4;
$blue-500: #1a86d9;
$blue-400: #5ca9e5;
$blue-300: #a9d3f2;
$blue-200: #d0e7f8;
$blue-100: #f3f9fd;

$green-100: #f1faf1;
$green-200: #caeaca;
$green-300: #55b155;
$green-400: #55b155;
$green-500: #218d21;
$green-600: #107C10;
$green-700: #0f700f;
$green-800: #0c5f0c;
$green-900: #094609;

$red-100: #fbf4f4;
$red-200: #f0d3d4;
$red-300: #e3afb2;
$red-400: #c86c70;
$red-500: #ae383e;
$red-600: #a4262c;
$red-700: #932227;
$red-800: #7c1d21;
$red-900: #5b1519;

$yellow-100: #fdfaf3;
$yellow-200: #f8ecd0;
$yellow-300: #f1dca9;
$yellow-400: #e3bb5b;
$yellow-500: #d79e1a;
$yellow-600: #d29200;
$yellow-700: #bc8400;
$yellow-800: #9f6f00;
$yellow-900: #755200;

$box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px, rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;

$font-family-dmsans: 'dmsans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

$screen-tab-1-breakpoint: 920px;
$screen-phone-1-breakpoint: 680px;

// typography
$label-xlarge-font-size: 22px;
$label-large-font-size: 20px;
$label-medium-font-size: 16px;
$label-small-font-size: 14px;
$label-xsmall-font-size: 12px;

$label-mobile-xlarge-font-size: 18px;
$label-mobile-large-font-size: 16px;
$label-mobile-medium-font-size: 14px;
$label-mobile-small-font-size: 12px;
$label-mobile-xsmall-font-size: 10px;

$text-xlarge-font-size: 18px;
$text-large-font-size: 16px;
$text-medium-font-size: 14px;
$text-small-font-size: 12px;
$text-xsmall-font-size: 10px;

$text-mobile-xlarge-font-size: 16px;
$text-mobile-large-font-size: 14px;
$text-mobile-medium-font-size: 12px;
$text-mobile-small-font-size: 10px;
$text-mobile-xsmall-font-size: 8px;

$h1-font-size: 42px;
$h2-font-size: 32px;
$h3-font-size: 28px;
$h4-font-size: 24px;
$h5-font-size: 22px;
$h6-font-size: 20px;

$h1-mobile-font-size: 32px;
$h2-mobile-font-size: 28px;
$h3-mobile-font-size: 24px;
$h4-mobile-font-size: 22px;
$h5-mobile-font-size: 20px;
$h6-mobile-font-size: 18px;