@import './../../../styles/config.scss';

.container {
    .card {
        width: 100%;
        padding: 10px;
        border-radius: 10px;
        background-color: $white;
        box-shadow: $box-shadow;

        .header {
            padding-bottom: 10px;
            border-bottom: 1px solid $gray-300;
        }

        &.hasTab {
            padding: 0px;
            
            .tabContent {
                padding: 20px;
            }
        }
    }
}



@media screen and (max-width: $screen-phone-1-breakpoint) {
    .container {
        flex-direction: column;

        .right {
            margin-left: 0px;
            margin-top: 20px;
            width: 100%;
        }
    }
}