@import './../../../../styles/config.scss';

.properties {
    width: 50%;
    max-width: 750px;
}

.preview {
    justify-content: center;
    align-items: center;
    background-color: $gray-800;
    border-radius: 4px;
    padding: 20px;

    .image {
        object-fit: contain;
    }
}