@import './../../../../../../styles/config.scss';

.container {
    width: 100%;
    
    .card {
        width: 100%;
        padding: 10px;
        border-radius: 10px;
        background-color: $white;
        box-shadow: $box-shadow;
    }
}

.tooltip {
    min-width: 200px;
    box-shadow: $box-shadow;
    border-radius: 10px;
    padding: 10px;
    background-color: $white;
}

.indicator {
    margin-top: 2px;
    width: 12px;
    height: 12px;
    border-radius: 3px;
}