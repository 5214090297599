@import './../../../styles/config.scss';

.container {
    .text {
        color: $gray-900;

        &:hover {
            color: $primary-600;
        }
    }
}