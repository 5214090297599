@import './../../../../../styles/config.scss';

.container {
    .card {
        position: relative;
        background-color: $gray-100;
        border-radius: 10px;

        .checkbox {
            opacity: 0;
            position: absolute;
            top: 10px;
            left: 10px;
            z-index: 2;
            -webkit-transition: opacity 0.3s;
            /* For Safari 3.0 to 6.0 */
            transition: opacity 0.3s;
            /* For modern browsers */
        }

        &:hover .checkbox,
        .checkbox.selected {
            opacity: 1;
        }

        .download {
            position: absolute;
            top: 10px;
            right: 10px;
            min-height: 27px;
            height: 27px;
            width: 27px;
            z-index: 2;
            color: $black;
            background-color: $gray-300;
            border: 1px solid $gray-600;

            &:hover {
                background-color: $gray-400;
            }
        }

        .fileImage {
            width: 100%;
            height: 125px;
            background-color: $gray-600;
            border: 1px solid $gray-600;
            border-radius: 10px;
            justify-content: center;
            align-items: center;
            font-size: 45px;
            z-index: 1;

            &.bgword {
                background-color: #e3ecfa;

                .icon {
                    color: #002050;
                }
            }

            &.bgexcel {
                background-color: #f2f9f5;

                .icon {
                    color: #094624;
                }
            }

            &.bgpowerpoint {
                background-color: #fcf0ed;

                .icon {
                    color: #740912;
                }
            }

            &.bgpdf {
                background-color: #fcf3f2;

                .icon {
                    color: #650b04;
                }
            }

            &.bgfile {
                background-color: $gray-300;

                .icon {
                    color: $black;
                }
            }

            &.bgimage {
                background-color: $gray-300;
            }

            .image {
                object-fit: cover;
                max-width: 100%;
                height: 100%;
            }
        }

        .content {
            margin-top: -8px;
            border: 1px solid $gray-600;
            border-top: 0px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            padding: 10px;
            padding-top: 18px;

            .name .text {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &:hover {
            cursor: pointer;
        }
    }
}