@import './config.scss';

.form-imageRadio {
    .ms-ChoiceFieldGroup-flexContainer {
        flex-flow: nowrap;
    }

    .ms-ChoiceField.ms-ChoiceField--image {
        flex-grow: 1;
        margin: 0px 20px 20px 0px;
        border-radius: 20px;

        &:last-child {
            margin:  0px;
        }

        .ms-ChoiceField-wrapper {
            width: 100%;
        }

        .ms-ChoiceField-innerField {
            width: 100%;
            height: auto;
        }

        .ms-ChoiceField-imageWrapper {
            .ms-Image {
                width: 100% !important;
                height: 100% !important;
            }
        }

        .ms-ChoiceField-labelWrapper {
            position: absolute;
            bottom: 0.5em;
            font-weight: 600;    
            width: 100%;
            max-width: unset;

            .ms-ChoiceFieldLabel {
                color: $neutralDark;
            }
        }

        // checked state
        label.ms-ChoiceField-field.ms-ChoiceField-field--image {
            border-radius: 20px;

            &::before {
                top: 8px;
                right: 8px;
            }
            
            &::after {
                top: 13px;
                right: 13px;
            }

            &.is-checked {
                background: $themePrimary;
                
                .ms-ChoiceField-labelWrapper .ms-ChoiceFieldLabel {
                    color: $neutralLighterAlt;
                }
            }
        }
    }

    @media (max-width: $screenBreakPoint1) {
        .ms-ChoiceFieldGroup-flexContainer {
            flex-flow: column;
        }

        .ms-ChoiceField.ms-ChoiceField--image {
            margin-right: 0px;

            label.ms-ChoiceField-field.ms-ChoiceField-field--image {
                .ms-Image-image {
                    max-height: 200px !important;
                    width: auto !important;
                    max-width: 100% !important;
                    margin: 0px auto !important;
                }
            }
        }
    }
}

.form-calendarPicker {
    .ms-DatePicker-wrap {
        background: #f3f2f1 !important;
        padding: 20px;
        border-radius: 10px;
    
        .ms-DatePicker-table {
            width: unset !important;

            .ms-DatePicker-day {
                padding: 3px !important;
                font-size: 14px;
                width: auto !important;
                font-weight: normal !important;

                &.ms-DatePicker-day--outfocus {
                    & > button {
                        background-color: $neutralLight;
                        color: $neutralSecondary !important;
                    }
                }

                & > button {
                    background-color: #fff;
                    border-radius: 5px;
                    width: 30px;
                    height: 30px;
                    color: $black !important;
                    cursor: pointer;
                }

                &.ms-DatePicker-day--highlighted > button {
                    background-color: $themePrimary !important;
                    color: $white !important;
                    font-weight: 600 !important;
                }
            }
        }
    }
}

.form-dropzone {
    border: 1px dashed #000;
    padding: 1em;
    text-align: center;

    .form-dropzoneIcon {
        font-size: 30px;
    }
}

.form-fieldErrorMessage {
    color: $color-redDark;
    animation-name: css-1, css-14;
    animation-duration: 0.367s;
    animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
    animation-fill-mode: both;
    -webkit-font-smoothing: antialiased;
}