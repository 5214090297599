@import './../../../../../../styles/config.scss';

.container {
    .selected {
        border-radius: 10px;
        box-shadow: $box-shadow;
        padding: 10px;
        margin-top: 3px;

        .deleteButton {
            min-height: unset;
            height: unset;
        }
    }
}

.createForm {
    .container {
        box-shadow: $box-shadow;
        background-color: $white;
        border-radius: 4px;
        padding: 10px;
        margin-bottom: 60px;
        overflow-y: auto;
    }

    .footer {
        position: fixed;
        background-color: $white;
        border-top: 1px solid $gray-600;
        height: 52px;
        bottom: 0px;
        left: 201px;
        right: 0px;
        padding: 0px 10px;
    }
}