@import './../../../../styles/config.scss';

.container {
    .list {
        .item {
            border: 1px solid $gray-300;
            border-radius: 10px;

            .icon {
                width: 80px;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;

                .image {
                    color: $white;
                    font-size: 22px;
                }

                &.bgword {
                    background-color: #e3ecfa;

                    .image {
                        color: #002050;
                    }
                }

                &.bgexcel {
                    background-color: #f2f9f5;

                    .image {
                        color: #094624;
                    }
                }

                &.bgpowerpoint {
                    background-color: #fcf0ed;

                    .image {
                        color: #740912;
                    }
                }

                &.bgpdf {
                    background-color: #fcf3f2;

                    .image {
                        color: #650b04;
                    }
                }

                &.bgfile {
                    background-color: $gray-300;

                    .image {
                        color: $black;
                    }
                }

                &.bgimage {
                    background-color: $gray-300;
                }
            }

            .imagePreview {
                min-width: 80px;
                max-width: 80px;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                object-fit: cover;
                height: 100%;
            }

            .label {
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }
    }

    .upload {
        padding: 20px;
        justify-content: center;
        align-items: center;
        border: 2px dashed $gray-600;
        border-radius: 10px;

        &:hover {
            cursor: pointer;
        }
    }
}