@import './../../styles/config.scss';

.label {
    font-family: $font-family-dmsans;
    font-weight: 600;

    &.clickable:hover {
        cursor: pointer;
        color: $primary-600;
    }

    &.xsmall {
        font-size: $label-xsmall-font-size;
    }

    &.small {
        font-size: $label-small-font-size;
    }

    &.medium {
        font-size: $label-medium-font-size;
    }

    &.large {
        font-size: $label-large-font-size;
    }

    &.required::after {
        content: '*';
        margin-left: 2px;
        font-size: 14px;
        color: $red-600;
    }
}

.labelContainer {
    .line {
        height: 1px;
        background-color: $gray-300;
    }
}

.text {
    font-family: $font-family-dmsans;
    padding: 0px;
    margin: 0px;
    text-wrap: wrap;

    &.xsmall {
        font-size: $text-xsmall-font-size;
    }

    &.small {
        font-size: $text-small-font-size;
    }

    &.medium {
        font-size: $text-medium-font-size;
    }

    &.large {
        font-size: $text-large-font-size;
    }
}

.heading {
    font-family: $font-family-dmsans;
    margin: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.h1 {
        font-size: $h1-font-size;
    }

    &.h2 {
        font-size: $h2-font-size;
    }

    &.h3 {
        font-size: $h3-font-size;
    }

    &.h4 {
        font-size: $h4-font-size;
    }

    &.h5 {
        font-size: $h5-font-size;
    }

    &.h6 {
        font-size: $h6-font-size;
    }
}

@media screen and (max-width: $screen-tab-1-breakpoint) {
    .label {
        &.xsmall {
            font-size: $label-mobile-xsmall-font-size;
        }

        &.small {
            font-size: $label-mobile-small-font-size;
        }

        &.medium {
            font-size: $label-mobile-medium-font-size;
        }

        &.large {
            font-size: $label-mobile-large-font-size;
        }
    }

    .text {
        &.xsmall {
            font-size: $text-mobile-xsmall-font-size;
        }

        &.small {
            font-size: $text-mobile-small-font-size;
        }

        &.medium {
            font-size: $text-mobile-medium-font-size;
        }

        &.large {
            font-size: $text-mobile-large-font-size;
        }
    }

    .heading {
        &.h1 {
            font-size: $h1-mobile-font-size;
        }

        &.h2 {
            font-size: $h2-mobile-font-size;
        }

        &.h3 {
            font-size: $h3-mobile-font-size;
        }

        &.h4 {
            font-size: $h4-mobile-font-size;
        }

        &.h5 {
            font-size: $h5-mobile-font-size;
        }

        &.h6 {
            font-size: $h6-mobile-font-size;
        }
    }
}