@import './../../../../styles/config.scss';

.container {    
    .card {
        background-color: $white;
        padding: 20px;
        border-radius: 10px;
        box-shadow: $box-shadow;
    }

    .border {
        border-bottom: 1px solid $gray-300;
        padding-bottom: 20px;
    }

    .vector {
        width: 75px;
    }
}