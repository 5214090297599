@import './../../../../styles/config.scss';

.container {
    .divider {
        height: 1px;
        background-color: $gray-300;
    }

    .selected {
        border-radius: 10px;
        box-shadow: $box-shadow;
        padding: 10px;
        margin-top: 3px;

        .deleteButton {
            min-height: unset;
            height: unset;
        }
    }

    .body {
        .right {
            align-self: flex-start; 
            width: 300px;
            background-color: $white;
            padding: 10px;
            border-radius: 10px;
        }

        .left {
            align-self: flex-start; 
            background-color: $white;
            border-radius: 10px;

            .form {
                padding: 20px;
            }
        }

        .footer {
            padding: 20px;
            border-top: 1px solid $gray-300;
        }
    }

    .pagination {
        background-color: $white;
        padding: 20px;
        border-radius: 10px;
        box-shadow: $box-shadow;

        .number {
            display: flex;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: $gray-300;
            justify-content: center;
            align-items: center;

            &.active {
                background-color: $green-800;
                color: $white;
            }
        }

        .title {
            &.active {
                color: $green-800;
            }
        }
    }
}

.priceColumn {
    background-color: $gray-200;
}

@media (max-width: $screenBreakPoint2) {
    .form {
        .address,
        .details {
            display: flex;
            flex-direction: column;

            & > * {
                width: 100%;
                margin: 0px;
            }
        }
    }
}