@import "./../styles/config.scss";

.header {
    width: 100%;
    border-bottom: 1px solid $neutralTertiaryAlt;
    align-items: center;

    .text {
        font-weight: 600;
        padding: 10px 5px;
        border-bottom: 3px solid $themePrimary;
    }
}

.authPage {
    width: 100%;

    .container {
        flex: 1;
    
        .content {
            margin:0px auto;
            height: 100%;
            width: 100%;
            max-width: 480px;
        }
    
        .background {
            z-index: 0;
            position: absolute;
            top: 0px;
            bottom: 0px;
            left: 0px;
            right: 0px;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            opacity: 0.4;
        }

        .leftContainer {
            min-height: 100vh;
            background: $neutralLight;
            width: 50%;
            padding: 40px;
            flex: 1;
    
            .logo {
                width: 90%;
                max-width: 250px;
            }
        }
    
        .rightContainer {
            position: relative;
            min-height: 100vh;
            padding: 40px;
            width: 50%;
            background: $neutralPrimary;
    
            .background {
                z-index: 0;
                position: absolute;
                top: 0px;
                bottom: 0px;
                left: 0px;
                right: 0px;
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
                opacity: 0.4;
            }
    
            .content {
                z-index: 1;

                & > * {
                    width: 100%;
                }
            }
    
            img {
                max-width: 100px;
            }
        }
    }
    
    @media (max-width: $screenBreakPoint1) {
        .container {
            .rightContainer {
                display: none;
            }

            .content {
                max-width: 680px;

                & > * {
                    width: 100%;
                }
            }
        }
    }
}