@import './../../../styles/config.scss';

.loadingComponent {
    &.withBackground {
        padding: 20px;
        border-radius: 10px;
        background: $white;
        box-shadow: $box-shadow;
        border-radius: $base-border-radius;
    }
}