@import "./../../../../../../styles/config.scss";

.container {
    box-shadow: $box-shadow;
    background-color: $white;
    border-radius: 10px;
    padding: 10px;

    .header {
        padding-bottom: 10px;
        border-bottom: 1px solid $gray-400;
    }
}

.income {
    color: $green-600;
}

.deduction {
    color: $red-600;
}