@import './../../../../../styles/config.scss';

.container {
    
}

.tooltip {
    min-width: 300px;
    box-shadow: $box-shadow;
    border-radius: 10px;
    padding: 10px;
    background-color: $white;
}

.indicator {
    margin-top: 2px;
    width: 12px;
    height: 12px;
    border-radius: 3px;
}