@import './../../../../../styles/config.scss';

.container {
    .card {
        padding: 10px;
        background-color: $gray-100;
        box-shadow: $box-shadow;
        border-radius: 5px;

        .pipe {
            margin-top: 2px;
            width: 3px !important;
            height: 20px;
            flex-shrink: 0;
        }

        .content {
            width: calc(25% - 10px);
        }
    }
}


@media screen and (max-width: $screen-tab-1-breakpoint) {
    .container {
        .card {
            .content {
                width: calc(33.3% - 10px);
            }
        }
    }
}


@media screen and (max-width: $screen-phone-1-breakpoint) {
    .container {
        .card {
            .content {
                width: calc(50% - 10px);
            }
        }
    }
}