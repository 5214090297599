@import './../../../../../styles/config.scss';

.item {
    position: relative;

    &:last-child {
        .line {
            display: none;
        }
    }

    .line {
        position: absolute;
        top: 29px;
        left: 14px;
        bottom: -30px;
        width: 1px;
        background-color: $gray-800;
    }

    .dot {
        margin-top: 20px;
        color: $gray-800;
    }

    .content {
        background-color: $gray-300;
        padding: 10px;
        border-radius: 10px;
        min-width: 450px;

        .divider {
            height: 1px;
            width: 100%;
            background-color: $gray-400;
        }
    }
}

@media screen and (max-width: $screen-phone-1-breakpoint) {
    .item {
        .content {
            min-width: unset;
            width: 100%;
        }
    }
}