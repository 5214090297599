@import './../../../styles/config.scss';

.container {
    background-color: $white;
    width: 100%;
    height: 100vh;

    .header {
        padding: 20px 10px;

        .logo {
            height: 50px;
        }
    }

    .division {
        background-color: $primary-600;
        border-bottom: 1px solid $gray-600;
        padding: 5px 20px;
    }
}

.top {
    height: calc(100vh - 60px);
    padding: 20px 0px;
    overflow-y: auto;
    overflow-x: hidden;
}

.bottom {
    border-top: 1px solid $gray-600;
    padding: 10px 0px;
    height: 60px;
    background-color: $white;
}

.menu {
    .group {
        padding: 0px 10px;
        color: $gray-900;
    }

    .item {
        padding: 8px 5px;
        color: $gray-900;
        border-radius: 5px;

        .icon {
            width: 20px;
        }

        &:hover,
        &:active {
            background-color: $gray-300;
            cursor: pointer;

            * {
                color: $dark;
            }
        }
    }
}

.mobile {
    display: none;
}

@media screen and (max-width: $screen-tab-1-breakpoint) {
    .container {
        height: 60px;
        border-bottom: 1px solid $gray-300;

        .header {
            padding: 10px 20px;

            .logo {
                height: 40px;
            }
        }

        .desktop {
            display: none;
        }
    }

    .top {
        height: unset;
        padding-top: 0px;
    }

    .bottom {
        border: 0px;
    }

    .mobile {
        display: block;
    }
}