@import './../../../../../../../../styles/config.scss';

.upload {
    padding: 20px;
    justify-content: center;
    align-items: center;
    border: 2px dashed $gray-600;
    border-radius: 10px;

    &:hover {
        cursor: pointer;
    }
}

.list {
    width: 500px;
}

.preview {
    justify-content: center;
    align-items: center;
    background-color: $gray-800;
    border-radius: 4px;
    padding: 20px;

    .image {
        object-fit: contain;
    }
}