@import './../../../../../../styles/config.scss';

.container {
    .commandbar {}

    .card {
        background-color: $white;
        padding: 5px;
        border-radius: 10px;
    }
}