@import './../../../styles/config.scss';

.container {
    &.medium {
        padding: 5px 8px;
        border-radius: 5px;
    }

    &.small {
        padding: 3px 5px;
        border-radius: 4px;
    }

    &.default {
        background-color: $gray-300;
        
        & > * {color: $dark;}
    }

    &.active {
        background-color: $blue-600;
        
        & > * {color: $white;}
    }
    
    &.success {
        background-color: $green-600;
        
        & > * {color: $white;}
    }
    
    &.primary {
        background-color: $primary-600;
        
        & > * {color: $white;}
    }
    
    &.warning {
        background-color: $yellow-600;
        
        & > * {color: $white;}
    }
    
    &.error {
        background-color: $red-600;
        
        & > * {color: $white;}
    }
    
    &.light {
        background-color: $white;
        
        & > * {color: $dark;}
    }
}

.link .container {
    &.default:hover {
        background-color: $gray-400;
    }
    
    &.active:hover {
        background-color: $blue-700;
    }
    
    &.success:hover {
        background-color: $green-700;
    }
    
    &.warning:hover {
        background-color: $yellow-700;
    }
    
    &.error:hover {
        background-color: $red-700;
    }
    
    &.light:hover {
        background-color: $gray-100;
    }
    
    &.primary:hover {
        background-color: $primary-600;
    }
}