@import './../../../../styles/config.scss';

.pivot {
    background-color: $white;
    border-radius: 8px;

    .button {
        padding: 6px 25px 5px;
        -webkit-transition: background-color 0.3s; /* For Safari 3.0 to 6.0 */
        transition: background-color 0.3s; /* For modern browsers */
        border-right: 1px solid $gray-400;

        &:hover {
            background-color: $gray-100;
        }

        &:hover {
            cursor: pointer;
        }

        &.active {
            background-color: $primary-600;
            color: $white;
        }

        &:first-child {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }

        &:last-child {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }
}