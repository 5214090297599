@import './../../../../../styles/config.scss';

.container {
    .calendar {
        margin-top: 20px;
        border: 1px solid $gray-200;
        border-radius: 5px;

        .header {
            background-color: $gray-200;
            padding: 10px;
            border-right: 1px solid $gray-300;

            .secondary {
                font-weight: normal;
                color: $gray-800;
            }
            
            &:last-child {
                border-right: 0px;
            }

            &.today {
                background-color: $primary-200;
            }
        }

        .column {
            padding: 10px;
            border-right: 1px solid $gray-300;
            vertical-align: top;
            
            &:last-child {
                border-right: 0px;
            }

            &.today {
                background-color: $primary-100;
            }
        }
    }

    .navigation {
        position: relative;

        .next, .prev {
            z-index: 1;
            position: absolute;
            top: 18px;

            .button {
                background-color: transparent;

                &:hover {
                    background-color: $primary-600;
                    color: $white;
                }
            }
        }

        .next {
            right: 10px;
        }

        .prev {
            left: 10px;
        }
    }

    .card {
        padding: 10px;
        background-color: $gray-100;
        box-shadow: $box-shadow;
        border-radius: 5px;

        .pipe {
            margin-top: 2px;
            width: 3px !important;
            height: 20px;
            flex-shrink: 0;
        }
    }
}