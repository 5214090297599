@import "./../styles/config.scss";

.container {
    .left {
        width: 200px;
    }

    .right {
        border-left: 1px solid $gray-300;
        width: calc(100vw - 200px);
        background-color: $gray-200;
    }

    .body {
        padding: 20px;
        height: calc(100vh - 90px);
        overflow-y: auto;
        overflow-x: hidden;
    }
}

@media screen and (max-width: $screen-tab-1-breakpoint) {
    .container {
        display: flex;
        flex-direction: column;

        .left, .right {
            width: 100%;
        }

        .body {
            height: calc(100vh - 140px);
        }
    }
}