@import './../../../../styles/config.scss';

.transactionSummary {
    .incomes {
        padding: 10px 20px;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        background-color: $green-200;
    }
    
    .outcomes {
        padding: 10px 20px;
        background-color: $red-200;
    }
    
    .total {
        padding: 10px 20px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        background-color: $blue-200;
    }
}