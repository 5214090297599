@import './../../../../styles/config.scss';

.card {
    background-color: $white;
    padding: 20px;
    box-shadow: $box-shadow;
    border-radius: 4px;

    &.primary {
        background-color: $primary-600;
        color: $white;
    }
}

.error {
    color: $red-600;
}

.success {
    color: $green-600;
}