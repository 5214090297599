@import './../../../styles/config.scss';

.timeline {
    .item {
        position: relative;
            
        .dot {
            position: relative;
            top: 4px;

            .infuture {
                color: $gray-800;
            }

            .completed {
                color: $green-800;
            }

            .inprogress {
                color: $yellow-800;
            }

            .cancelled {
                color: $red-800;
            }

            .active {
                color: $blue-600;
            }
        }

        .line {
            position: absolute;
            width: 0px;
            left: 6px;
            top: 16px;
            bottom: -19px;

            &.solid {
                border-left: 1px solid $gray-800;
            }

            &.dashed {
                border-left: 1px dashed $gray-800;
            }
        }

        .content {
            .date {
                color: $gray-800;
                margin-top: 5px;
            }

            .notes {
                margin-top: 2px;
                color: $gray-800;
                font-style: italic;
            }
        }
    }
}