@import './../../../../../styles/config.scss';

.divider {
    height: 1px;
    background-color: $gray-300;
}

.picker {
    .selected {
        border-radius: 10px;
        box-shadow: $box-shadow;
        padding: 10px;
        margin-top: 3px;

        .deleteButton {
            min-height: unset;
            height: unset;
        }
    }
}

.priceColumn {
    background-color: $gray-200;
}