@import './../../../../../../styles/config.scss';

.tooltip {
    min-width: 300px;
    box-shadow: $box-shadow;
    border-radius: 10px;
    padding: 10px;
    background-color: $white;
}

.indicator {
    margin-top: 2px;
    width: 12px;
    height: 12px;
    border-radius: 3px;
}

.card {
    box-shadow: $box-shadow;
    padding: 20px;
    border-radius: 4px;
    background-color: $white;

    &.nopadding {
        padding: 0px;
    }
}

@media (max-width: $screenBreakPoint1) {
    .parent {
        flex-direction: column;
        gap: 20px;

        & > * {
            margin: 0px !important;
            width: 100% !important;
            max-width: unset;
        }
    }
}