@import './../../../../../styles/config.scss';

.newsForm {
    .form {
        padding: 20px;
        background-color: $white;
        border-radius: 10px;
        box-shadow: $box-shadow;
    }

    .footer {
        padding: 20px;
        background-color: $white;
        border-radius: 10px;
        box-shadow: $box-shadow;
    }
}