.container {
    width: 100%;
    max-width: 400px;
    padding: 10px;
    margin: 0px auto;
    text-align: center;

    .image {
        max-width: 100%;
    }

    .text {
        text-align: center;
    }
}