@import './../../../styles/config.scss';

.container {
    position: relative;
    width: 100%;
    height: 90px;
    padding: 0px 20px;
    background-color: $white;
    border-bottom: 2px solid $gray-200;

    .separator {
        position: absolute;
        width: 2px;
        background-color: $gray-200;
        top: 0px;
        bottom: 0px;
        left: 0px;
    }

    .left {
        width: calc(100% - 70px);
    }

    .right {
        width: 50px;
    }
}

@media screen and (max-width: $screen-tab-1-breakpoint) {
    .container {
        height: 80px;
    }
}