@import "./../../../styles/config.scss";

.container {
    z-index: 999;
    position: fixed;
    top: 10px;
    width: 450px;
    left: 50%;
    margin-left: -150px; /* Negative half of width. */

    .item {
        padding: 8px 12px;
        border-radius: 10px;
        box-shadow: $box-shadow;

        &.default {
            background-color: $gray-300;
            
            & > * {color: $dark;}
        }
        
        &.success {
            background-color: $green-200;
            
            & > * {color: $dark;}
        }
        
        &.warning {
            background-color: $yellow-200;
            
            & > * {color: $dark;}
        }
        
        &.error {
            background-color: $red-200;
            
            & > * {color: $dark;}
        }
        
        &.light {
            background-color: $white;
            
            & > * {color: $dark;}
        }
    }
}