@import './../../../../styles/config.scss';

.container {
    .card {
        position: relative;
        background-color: $gray-100;
        border-radius: 4px;

        .fileImage {
            width: 100%;
            border-radius: 4px;
            background-color: $gray-600;
            border: 1px solid $gray-600;
            justify-content: center;
            align-items: center;
            z-index: 1;

            &.small {
                width: 50px;
                height: 50px;
            }
    
            &.medium {
                width: 75px;
                height: 75px;
            }
    
            &.large {
                width: 100px;
                height: 100px;
            }

            &.bgword {
                background-color: #e3ecfa;

                .icon {
                    color: #002050;
                }
            }

            &.bgexcel {
                background-color: #f2f9f5;

                .icon {
                    color: #094624;
                }
            }

            &.bgpowerpoint {
                background-color: #fcf0ed;

                .icon {
                    color: #740912;
                }
            }

            &.bgpdf {
                background-color: #fcf3f2;

                .icon {
                    color: #650b04;
                }
            }

            &.bgfile {
                background-color: $gray-300;

                .icon {
                    color: $black;
                }
            }

            &.bgimage {
                background-color: $gray-300;
            }

            .image {
                object-fit: cover;
                max-width: 100%;
                height: 100%;
            }
        }
    }
}