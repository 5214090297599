@import './../../../../../styles/config.scss';

.container {
    .selected {
        margin-top: 3px;
        border-radius: 10px;
        box-shadow: $box-shadow;
        padding: 10px;

        .deleteButton {
            min-height: unset;
            height: unset;
        }
    }
}