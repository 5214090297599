@import './../../styles/config.scss';

.login {
    width: 100%;
    max-width: 400px;
    margin: 0px auto;

    .userCard {
        height: auto;
        padding: 10px;
        width: 100%;
        border: 1px solid $neutralTertiaryAlt;
        border-radius: $base-border-radius;

        &:hover, &:focus {
            background: $neutralTertiaryAlt;
            * {color: $black}
        }

        .userIcon {
            height: 100%;
            padding: 8px;
        }
    }
}