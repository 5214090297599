@import './../../../../../styles/config.scss';

.container {
    -webkit-print-color-adjust: exact !important;
    padding: 20px;

    .divider {
        height: 1px;
        width: '100%';
        background-color: $gray-600;
    }

    .header {
        .logo {
            height: 65px;
        }
    }

    .costingTable {
        &table, th, td {
            border: 1px solid $gray-300;
            border-collapse: collapse;
        }

        td, th {
            text-align: left;
            vertical-align: top;
            padding: 10px;
        }

        .priceColumn {
            background-color: $gray-100;
        }
    }
}