@import './../../../../styles/config.scss';

.container {
    .card {
        position: relative;
        background-color: $gray-100;
        width: 15%;

        &.large {
            border-radius: 4px;
            min-width: 200px;
            max-width: 250px;

            .fileImage {
                height: 125px;
                font-size: 45px;

                .image {
                    border-radius: 4px;
                }
            }

            .content {
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
                padding: 10px;
                padding-top: 18px;
            }
        }

        &.medium {
            border-radius: 8p4pxx;
            min-width: 150px;
            max-width: 200px;

            .fileImage {
                height: 100px;
                font-size: 40px;
                border-radius: 4px;

                .image {
                    border-radius: 4px;
                }
            }

            .content {
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
                padding: 10px;
                padding-top: 18px;
            }
        }

        &.small {
            border-radius: 4px;
            min-width: 125px;
            max-width: 150px;

            .fileImage {
                height: 75px;
                font-size: 30px;
                border-radius: 4px;

                .image {
                    border-radius: 4px;
                }
            }

            .content {
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
                margin-top: -2px;
                padding: 6px;
            }
        }

        .checkbox {
            opacity: 0;
            position: absolute;
            top: 10px;
            left: 10px;
            z-index: 2;
            -webkit-transition: opacity 0.3s;
            /* For Safari 3.0 to 6.0 */
            transition: opacity 0.3s;
            /* For modern browsers */
        }

        &:hover .checkbox,
        .checkbox.selected {
            opacity: 1;
        }

        .download {
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 2;
            color: $black;
            background-color: $gray-300;
            border: 1px solid $gray-600;
            min-height: 27px;
            height: 27px;
            width: 27px;

            &:hover {
                background-color: $gray-400;
            }
        }

        .fileImage {
            border-radius: 4px;
            width: 100%;
            background-color: $gray-600;
            border: 1px solid $gray-600;
            justify-content: center;
            align-items: center;
            z-index: 1;

            &.bgword {
                background-color: #e3ecfa;

                .icon {
                    color: #002050;
                }
            }

            &.bgexcel {
                background-color: #f2f9f5;

                .icon {
                    color: #094624;
                }
            }

            &.bgpowerpoint {
                background-color: #fcf0ed;

                .icon {
                    color: #740912;
                }
            }

            &.bgpdf {
                background-color: #fcf3f2;

                .icon {
                    color: #650b04;
                }
            }

            &.bgfile {
                background-color: $gray-300;

                .icon {
                    color: $black;
                }
            }

            &.bgimage {
                background-color: $gray-300;
            }

            .image {
                object-fit: cover;
                max-width: 100%;
                height: 100%;
                width: 100%;
                object-position: center;
            }
        }

        .content {
            margin-top: -8px;
            border: 1px solid $gray-600;
            border-top: 0px;

            .name .text {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &:hover {
            cursor: pointer;
        }
    }
}

@media screen and (max-width: $screen-phone-1-breakpoint) {
    .container {
        .card {
            width: calc(50% - 20px);
            min-width: unset;
            max-width: unset;
        }
    }
}