@import './../../../../../styles/config.scss';

.details {
    .images {
        .item {
            width: calc(50% - 10px);
            height: 200px;
            background-size: cover;
            background-position: center;
            border-radius: 10px;
        }
    }
}