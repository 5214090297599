@import './../../../../styles/config.scss';

.container {
    .item {
        background-color: $white;
        border-radius: 10px;
        padding-left: 10px;
        box-shadow: $box-shadow;

        .action {
            border-left: 1px solid $gray-300;

            .button {
                border-radius: 0px !important;
                border-top-right-radius: 10px !important;
                border-bottom-right-radius: 10px !important;

                &:hover {
                    background-color: $gray-200 !important;
                }
            }
        }
    }
}