@import './../../../styles/config.scss';

.container {
    .list {
        margin: 0px;
        padding: 0px;

        .item {
            list-style-type: none;
            display: inline-block;

            .link, &.helper {
                display: inline-block;
                color: $black;
                padding: 4px 12px;
                margin: 0px 2px;
                border-radius: 5px;
                font-family: $font-family-dmsans;
            }

            &.helper {
                padding: 4px;
            }

            .link {
                &.loading {
                    padding: 7px 7px;
                    top: 3px;
                    position: relative;
                }

                &:hover {
                    background-color: $gray-400;
                }
            }

            &.active .link {
                background-color: $primary-600;
                color: $white;
            }
        }
    }

    .button {
        height: 30px;
        min-height: 30px;

        &:not([disabled]) {
            * {
                color: $black;
            }

            &:hover {
                background-color: $gray-400;
            }
        }
    }
}